import { css } from "@emotion/react";
import { NoSsr } from "@mui/base";
import { PageType, theme } from "@product/scmp-sdk";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { Fragment } from "react";

import { config, tracking } from "shared/data";

import { HamburgerMenu } from "scmp-app/components/posties/hamburger-menu";
import { hamburgerMenuAtom } from "scmp-app/components/posties/hamburger-menu/atoms";
import {
  Item as HamburgerMenuItem,
  SubItem as HamburgerMenuSubItem,
  StyledIconHome,
  StyledIconStar,
} from "scmp-app/components/posties/hamburger-menu/styles";
import { postiesMenuData as brochureHomepageMenuData } from "scmp-app/components/posties/header/posties-brochure-header/header-menu/data";
import { useMenuData as useContentHomepageMenuData } from "scmp-app/components/posties/header/posties-content-header/header-menu/hooks";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { page as postiesKidsPage } from "scmp-app/pages/posties/kids";
import { page as postiesParentsPage } from "scmp-app/pages/posties/parents";

import { DynamicHeaderAvatar } from "./dynamics";
import { RedirectButton } from "./redirect-button";
import { ActionButton, Container, FeaturesWrapper, RedirectButtonContainer } from "./styles";
import type { PostiesMenuActionButtonLabel } from "./types";

export type Props = {
  className?: string;
};

export const HeaderActions: FunctionComponent<Props> = ({ className }) => {
  const setHamburgerMenuState = useSetAtom(hamburgerMenuAtom);

  const pageType = useCurrentPageType();
  const { asPath } = useRouter();

  const currentURLObject = new URL(asPath, config.general.siteUrl);

  const { menuData: contentHomepageMenuData } = useContentHomepageMenuData();
  const { pathname: contentHomepagePathname, query: contentHomepageQuery } = postiesKidsPage.route(
    {},
  );
  const { pathname: brochureHomepagePathname, query: brochureHomepageQuery } =
    postiesParentsPage.route({});

  const isPostiesBrochureHomePage = pageType === PageType.PostiesParents;

  const label: PostiesMenuActionButtonLabel = isPostiesBrochureHomePage
    ? "For Kids"
    : "For Parents";

  const redirectButtonProps = isPostiesBrochureHomePage
    ? {
        label,
        pathname: contentHomepagePathname,
        query: contentHomepageQuery,
      }
    : {
        label,
        pathname: brochureHomepagePathname,
        query: brochureHomepageQuery,
      };

  const renderHamburgerMenu = () => {
    const postiesHamburgerMenuQueryParameters = {
      module: tracking.module.HamburgerMenuPosties,
      pgtype: pageType,
    };

    const handleClose = () => {
      setHamburgerMenuState(current => ({
        ...current,
        isOpen: false,
      }));
    };

    const getHamburgerMenuItemClickHandler = () => {
      handleClose();
    };

    const handleHamburgerMenuRedirectButtonClick = () => {
      handleClose();
    };

    const renderMenuItems = () =>
      isPostiesBrochureHomePage
        ? brochureHomepageMenuData.map((item, index) => (
            <HamburgerMenuItem
              $isActive={
                item.urlAlias === currentURLObject.pathname && item.hash === currentURLObject.hash
              }
              hash={item.hash}
              key={index}
              onClick={getHamburgerMenuItemClickHandler}
              pathname={item.urlAlias}
              query={{ ...item.query, ...postiesHamburgerMenuQueryParameters }}
            >
              <StyledIconStar />
              {item.name}
            </HamburgerMenuItem>
          ))
        : contentHomepageMenuData.map((item, itemIndex) => (
            <Fragment key={itemIndex}>
              <HamburgerMenuItem
                $isActive={item.urlAlias === currentURLObject.pathname}
                onClick={getHamburgerMenuItemClickHandler}
                pathname={item.urlAlias ?? ""}
                query={{ ...item.query, ...postiesHamburgerMenuQueryParameters }}
              >
                <StyledIconStar />
                {item.urlAlias === contentHomepagePathname && <StyledIconHome />}
                {item.name}
              </HamburgerMenuItem>
              {item.subMenuLinkProps?.map((subItem, subItemIndex) => (
                <HamburgerMenuSubItem
                  $isActive={subItem.urlAlias === currentURLObject.pathname}
                  key={subItemIndex}
                  onClick={getHamburgerMenuItemClickHandler}
                  pathname={subItem.urlAlias}
                  query={{ ...subItem.query, ...postiesHamburgerMenuQueryParameters }}
                >
                  <StyledIconStar />
                  {subItem.name}
                </HamburgerMenuSubItem>
              ))}
            </Fragment>
          ));
    return (
      <HamburgerMenu>
        <NoSsr>
          {renderMenuItems()}
          <RedirectButton
            onClick={handleHamburgerMenuRedirectButtonClick}
            pathname={redirectButtonProps.pathname}
            query={{
              ...redirectButtonProps.query,
              ...postiesHamburgerMenuQueryParameters,
            }}
          >
            {label}
          </RedirectButton>
        </NoSsr>
      </HamburgerMenu>
    );
  };

  return (
    <Container className={className}>
      <FeaturesWrapper>
        <ActionButton
          $responsiveVariants={{
            desktopUp: "show",
            largeDesktopUp: "show",
            mobileUp: "hidden",
            tabletUp: "show",
          }}
        >
          <RedirectButtonContainer>
            <RedirectButton
              pathname={redirectButtonProps.pathname}
              query={{
                ...redirectButtonProps.query,
                module: tracking.module.HeaderPosties,
                pgtype: pageType,
              }}
            >
              {label}
            </RedirectButton>
          </RedirectButtonContainer>
        </ActionButton>
        <ActionButton
          $responsiveVariants={{
            desktopUp: "show",
            largeDesktopUp: "show",
            mobileUp: "show",
            tabletUp: "show",
          }}
        >
          <DynamicHeaderAvatar />
        </ActionButton>
        <ActionButton
          $extraCss={css`
            ${theme.breakpoints.up("tablet")} {
              margin-inline-start: 8px;
            }
          `}
          $responsiveVariants={{
            desktopUp: "hidden",
            largeDesktopUp: "hidden",
            mobileUp: "show",
            tabletUp: "show",
          }}
        >
          {renderHamburgerMenu()}
        </ActionButton>
      </FeaturesWrapper>
    </Container>
  );
};

HeaderActions.displayName = "HeaderActions";

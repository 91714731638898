import { PageType } from "@product/scmp-sdk";

import { config } from "shared/data";

import { pathname as postiesEventsPathname } from "scmp-app/pages/posties/events";
import { pathname as postiesParentsPathname } from "scmp-app/pages/posties/parents";

const subscribeHost = config.rosetta.subscribeHost;

export const postiesMenuData = [
  {
    hash: "#why-posties",
    name: "Why Posties?",
    query: {
      pageType: PageType.PostiesParents,
    },
    urlAlias: postiesParentsPathname,
  },
  {
    hash: "#news",
    name: "News",
    query: {
      pageType: PageType.PostiesParents,
    },
    urlAlias: postiesParentsPathname,
  },
  {
    hash: undefined,
    name: "Events",
    query: {
      pageType: PageType.PostiesEvents,
    },
    urlAlias: postiesEventsPathname,
  },
  {
    hash: "#subscribe",
    name: "Subscribe",
    query: {
      pageType: PageType.PostiesParents,
    },
    urlAlias: postiesParentsPathname,
  },
  {
    hash: undefined,
    name: "Redeem",
    query: {},
    urlAlias: `${subscribeHost}/posties/redeem`,
  },
] as const;
